* {
  margin: 0;
  padding: 0;
}
// #root{
//   background-color:#272727!important;
// }
body{
  overflow-x: hidden;
}
#multi-item-example-new {
  color: #fff !important;
}

.hwrap {
  color: #000 !important;
  overflow-x: hidden;
}
::-webkit-scrollbar {
   width: 20px !important;
   height: 2px!important;
  background-color: transparent !important;
 
}
::-webkit-scrollbar-thumb {
  background-color: #2C2C2C;    /* color of the scroll thumb */
  border-radius: 0px;       /* roundness of the scroll thumb */
 
}



.body1 {
  text-align: center;
}

// html,
// body,
// .row {
//   overflow-x: 0 !important;
// }

.notification-link {
  cursor: pointer;
  color: blue;
}

.wrapper {
  width: 1170px;
  margin: 0 auto;
}

header {
  height: auto;
  // background-color: #fff;
  color:#252525;
  width: 100%;
  z-index: 10;
  position: relative;
}

.logo {
  width: 30%;
  float: left;
  line-height: 100px;
}

.logo a {
  text-decoration: none;
  font-size: 30px;
  font-family: poppins;
  color: #fff;
  letter-spacing: 5px;
  // margin-left: -500px;
}

.font-class {
  p b {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    line-height: 1.7 !important;
    font-size: 20px !important;

  }
}
.font-class {
    text-align: left!important;

  
}

.video_main_page {
  max-width: 85%;
}

.featured_video_adjustment {
  position: relative;
  top: 16%;
}

.play_button {
  font-size: 60px;
  position: absolute;
  color: white;
  left: 48%;
  top: 39%;
  z-index: 1200;
}

.mute_button {
  font-size: 29px;
  position: absolute;
  top: 89%;
  left: 10%;
  color: white !important;
  z-index: 1200;
  cursor: pointer;
}

.MsoNormal {
  b {
    color: white;
  }

  span {
    // font-size: 20px !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    line-height: 1.7 !important;
  }

  b span {
    // font-size: 4.45rem !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    line-height: 1.7 !important;
  }

  i span {
    // font-size: 20px !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    line-height: 1.7 !important;
    font-weight: bold;
  }
}

.MsoListParagraphCxSpFirst {
  span {
    // font-size: 20px !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    line-height: 1.7 !important;
  }
}

.MsoListParagraphCxSpMiddle {
  span {
    // font-size: 20px !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    line-height: 1.7 !important;
  }
}

.MsoListParagraphCxSpLast {
  span {
    // font-size: 20px !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    line-height: 1.7 !important;
  }
}

.gmail- {
  span {
    // font-size: 20px !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    line-height: 1.7 !important;
  }

  i {
    // font-size: 20px !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    line-height: 1.7 !important;
  }
}

nav1 {
  float: right;
  line-height: 100px;
  margin-right: -140px;
}

nav1 a {
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: bolder;
  margin: 20px;
  color: #fff;
}

nav1 a:hover {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
}

nav2 {
  line-height: 100px;
  color: Black;
}

nav2 a {
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  margin: 20px;
  color: black;
}

nav2 a:hover {
  font-family: Arial, Helvetica, sans-serif;
  color: black;
  text-decoration: none;
  transition: 0.3s;
}

.content-area {
  width: 100%;
  position: relative;
  top: 450px;
  background: #ebebeb;
  height: 1500px;
}

// .modal-content{
//   height: 300px;
// }
// .modal-body{
//   height: 250px;
// }
.content-area h2 {
  font-family: poppins;
  letter-spacing: 4px;
  padding-top: 30px;
  font-size: 40px;
  margin: 0;
}

.content-area p {
  padding: 2% 0px;
  font-family: poppins;
  line-height: 30px;
}

.caret {
  font-family: Arial, Helvetica, sans-serif;
  font-stretch: semi-condensed;
  font-size: 20px;
  font-weight: bolder;
  color: #101f3e;
  margin: 15px;
}

.caretnew {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bolder;
  color: black;
  margin: 10px;
}

.caretnew a {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: black;
  margin: 10px;
}

.caretnew a:hover {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: black;
  margin: 10px;
}

.fa-circle:before {
  margin-right: 15px;
}

h2.header1 {
  font-weight: bolder;
  padding-top: 20px;
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  font-stretch: semi-condensed;
  color: white;
}

h2.seperator {
  width: 90%;
  text-align: center;
  // border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

h2.seperator span {
  // background: #fff;
  padding: 0 10px;
  // color: #fff;
}

h2.header {
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 20px;
  font-size: 30px;
  font-weight: bold;
  color: #101f3e;
}

h4.heading {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: black;
}

h4.options {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  margin-left: 70px;
  padding-top: 15px;
}

h4.categories {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  margin-left: 110px;
  padding-top: 20px;
  margin-bottom: 10px;
}

h6.title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 9px;

  color: black;
}

h6.title1 {
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 10px;
  font-size: 13px;
  font-weight: bold;
  color: white;
}

.card-img-partner {
  width: 50%;
  height: 20vh;
  margin-left: 10px;
}

.card-text-white {
  margin-bottom: 20px;
}

p.timeline-Tweet-text {
  font-size: 16px;
  line-height: 14px;
  font-weight: 300;
}

.TweetAuthor {
  max-width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important;
  margin-bottom: 20px;
}



.list-group-item {
  background-color: transparent;
}

.btn.btn-dark {
  padding: 60px;
}

// .btn {
//   background-color: DodgerBlue;
//   border: none;
//   color: white;
//   padding: 12px 16px;
//   font-size: 16px;
//   cursor: pointer;
// }

/* Darker background on mouse-over */
// .btn:hover {
//   background-color: RoyalBlue;
// }
// .raffle {
//   .ant-popover-buttons {
//    display: none !important
//   }
// }
#raffle .ant-popover-buttons {
  display: none !important;
}

.sold_out {
  color: #fff;
  left: -140%;
  bottom: 85%;
  display: block;
  text-align: center;
  position: relative;
  text-decoration: none;
  letter-spacing: 0.06em;
  background-color: #a00;
  padding: 0.5em 2em 0.4em 2em;
  text-shadow: 0 0 0.75em #444;
  border: 2px rgba(255, 255, 255, 0.7) dashed;
  font: bold 16px/1.2em Arial, Sans-Serif;
  -webkit-text-shadow: 0 0 0.75em #444;
  -webkit-transform: rotate(-45deg) scale(0.75, 1);
  z-index: 10;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 40px;
  margin-left: 40px;
  color: #247ca7;
  border-top: 0.7px solid rgba(0, 0, 0, 0);
}

hr.auctions {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 40px;
  margin-left: 40px;
  color: black;
  border-top: 0.7px solid rgba(0, 0, 0, 0);
}

.btn.btn-secondary.btn-circle1 {
  margin: 3px;
  width: 17px;
  height: 17px;
  padding: 0px;
  border-radius: 10px;
  text-align: center;
  font-size: 10px;
  background-color: white;
}

.fa.fal.fa {
  color: black;
}

.border-bottoms {
  border-bottom: 2px solid #ddd !important;
}

.border-right {
  border-right: 2px solid #ddd !important;
}

.ul-alignment {
  text-align: justify;
  margin-left: 45%;
}

.sorting-Asc-desc-position {
  display: flex;
  gap: 6px;
  justify-content: flex-end;
  align-items: center;
}

.borders {
  border-bottom: 2px solid #ddd;
  border-top: 2px solid #ddd;
  padding-top: 20px;
}

.border-all-side {
  border-bottom: 2px solid #ddd;
  border-right: 2px solid #ddd;
  border-left: 2px solid #ddd;
}

.border-bottom-ul {
  border-bottom: 2px solid #ddd;
}

.border-top-ul {
  border-top: 2px solid #ddd;
}

.border-bottom {
  border-bottom: 2px solid #ddd !important;
}

.page-content {
  min-height: calc(100vh - 390px);
}

.socialbtn {
  width: 100%;
  padding: 10px;
  border-radius: 0px;
  margin: 5px 0;
  display: inline-block;
  line-height: 20px;
  text-decoration: none;
  /* remove underline from anchors */
}

.socialbtn:hover {
  opacity: 0.9;
  text-decoration: none;
  color: white;
}

.iconsbtn {
  width: 100%;
  padding: 3px;
  border-radius: 0px;
  margin: 5px 0;
  display: inline-block;
  line-height: 20px;
  text-decoration: none;
  /* remove underline from anchors */
}

.iconsbtn:hover {
  opacity: 0.9;
  text-decoration: none;
  color: white;
}

.facebook {
  background-color: #3b5998;
  border-radius: 0px;
  color: white;
}

.g {
  background-color: #dd4b39;
  border-radius: 0px;
  color: white;
}

.fb {
  background-color: #3b579d;
  margin-left: -10px;
  color: white;
}

.twitter {
  background-color: #55acee;
  color: white;
}

.google {
  background-color: #dd4b39;
  margin-left: -10px;
  color: white;
}

.account {
   background-color: #015fbc!important;
  color: white;
}

.btn-close {
  color: black;
  font-size: 12px;
}


.modal-content {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 35px;
  margin-top: 80px;
  pointer-events: auto;
  // background-color: #fff;
  background-clip: padding-box;
  border-radius: 1rem;
  outline: 0;
}

.form-check-input:checked {
  background-color: grey;
  border-color: black;
}

form input[type='radio'],
form input[type='checkbox'] {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  margin-top: 3px;
  background-color: transparent;
}

// .container,
// .container-sm,
// .container-md,
// .container-lg,
// .container-xl {
//   text-align: left;
//   margin-left: 130px;
// }

// .img-box {
//   margin-left: 50px;
// }

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(
    .invalid-feedback
  ) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 43px;
  // background-color: #000 !important;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  // background-color: white;
}

.form-group {
  // box-shadow: 0px 0px 10px #cecece;
}

.img-responsive {
  width: 105%;
  height: 85%;
  margin-bottom: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 230px;
  z-index: 1;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #2c2c2c;
  width: 240px;
  margin-bottom: 10px;
  text-align: center;
  // margin-left: 0px;
}

.dropdown-content.lists {
  // margin-left: -100px;
  border-radius: 10px;
}

.dropdown-content a.bar {
  // color: white;
  text-decoration: none;
  display: block;
  border-bottom: #252525 1px solid;
  margin: 0px;

  line-height: 3;
}

.dropdown-content a.bar:last-child {
  border-bottom: none !important;
}

.header_font {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.dropdown-content a.bar:hover {
  // background-color: white;
  // color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.ant-calendar-picker-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
  padding-right: 34px;
  margin-top: -18px;
}

.ant-calendar-picker-input.ant-input {
  margin-left: 3px;
  height: 45px;
  margin-left: -20px;
  margin-top: -10px;
  margin-bottom: 7px;
}

.ant-input {
  background-color: #fff !important;
  border: 0 !important;
  color: #000;
  padding: 10px !important;
  width: 100% !important;
  height: 3.3em !important;
  border-radius: 4px !important;
}

.labeltext {
  margin-right: 40%;
}

.labeltext1 {
  margin-right: 80%;
}

.modal-header {
  color: white;
  margin-top: -30px;
  margin-bottom: -20px;
  border-bottom: 0px;
}

.close {
  float: right;
  font-size: 26px !important;
  line-height: 1;
  color: #fff;
  // text-shadow: 0 1px 0 #fff;
}

.close:hover {
  color: #fff !important;
  text-decoration: none;
}

.modal-header {
  margin-bottom: -13px !important;
}

.ant-popover-placement-top {
  z-index: 99999;
}

.carouselstyle {
  max-width: 980px;
  min-width: 150px;
  background: transparent;
  border: none;
}

.ant-select-selection--single {
  height: 35px;
}

/*Basit*/
// #bidcard-carousel-icon-prev {
//   left: 40px !important;
// }
// #bidcard-carousel-icon-next {
//   right: -60px !important;
// }
.item-slide-video{
   max-width: 100%; 
   width: 100%; 
   height: auto;
   object-fit: contain;
}
.li_styles_icons{
  img{
        filter: invert(1)!important;}}
.responsive-menu-ul{
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 40px;
}
.inner-responsive-menu-ul{
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 40px;

}
// #bidcard-carousel-icon-prev {
//   left: 40px !important;
// }
// #bidcard-carousel-icon-next {
//   right: -60px !important;
// }
.carousel-indicators {
  margin: -40px;
  bottom: 18px;
}
.inner-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0 !important;
}

.header1 {
  margin-left: 0 !important;
  padding-top: 0 !important;
}

.dashboard_car {
  background: #dbb265;
  min-height: 500px;
  padding-top: 30px;
  padding-bottom: 40px;
}

.main-slide-image {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
}
.responsive-menu-ul {
  margin-left: 20px;
  a{
    // color: #252525!important;
  }
}
img.mt-4.mb-5.bg-white.rounded {
  padding: 10px 0 !important;
  margin: 0;
}

.slide-image-overflow {
  height: 390px;
  overflow: hidden;
}

a.btn.btn.py-2.px-5 {
  background-color: rgb(219, 178, 101);
}

.custom-select {
  width: 100% !important;
  margin-left: 0 !important;
}

.select-dropdown {
  width: 100%;
}

.input-group-prepend .col-md-12 {
  padding: 0 !important;
}

.myitem-portfolio-heading {
  margin-bottom: 0 !important;
}

.myitem-content-body {
  margin-top: 0 !important;
}

.main-myitems {
  padding: 30px;
}

.profile-btn-last {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

/*End-basit*/
.carousel-indicators li {
  box-sizing: content-box;
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: white;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.9;
  transition: opacity 0.6s ease;
  border-radius: 100%;
}

.carousel-indicators .active {
  opacity: 1;
  background-color: black;
}

#headerBgColor {

  width: 100%;
  z-index: 10;
  position: relative;
  line-height: 30px;
  text-align: center;
}

.brand {
  width: 75px;
  float: left;
  line-height: 100px;
  margin: 0 0 0 5%;
}

.menu {
  // width: 60%;
  // height: 100%;
  float: right;
}

// .menu ul {
//   // margin-top: 25px;
//   text-decoration: none;
//   font-family: "Montserrat" !important;
//   font-size: 16px;
//   font-weight: 500;
//   padding-top: 10px;
//   // color: #fff;
//   width: 100%;
// }

.menu ul {
  text-decoration: none;
  font-family: 'Montserrat' !important;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  /* padding-top: 10px; */
  bottom: -10px !important;
  width: 100%;
  // right: 60px;
}

.menu ul a {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  margin: 25px 0!important;
  text-decoration: none;
  color: #252525;
  display: inline-block;
}

// .menu ul a:hover {
//   // color: #fff;
//   font-weight: bold;
//   transition: 0.3s;
// }

#menuToggle {
  display: none;
}

.menu-icon {
  display: none;
}

.inner-card {
  margin-left: 180px;
}

.google_login {
  background-color: white;
  display: inline-flex;
  align-items: center;
  color: #000!important;
  padding: 0px;
  border-radius: 2px;
  width: 40px !important;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  span{
    color:#000!important;
  }
}

.ant-modal-header {
  color: #fff;
  background: #000;
  border-bottom: none;
}

.ant-notification-notice {
  background-color: #000 !important;
  color: white !important;
}

.ant-notification-notice {
  background-color: #000 !important;
  color: white !important;
}

.notification-link {
  color: white !important;
}

.ant-notification-notice-description {
  background-color: #000 !important;
  color: white !important;
}

.ant-notification-notice-description h4 {
  background-color: #000 !important;
  color: white !important;
}

.ant-notification-notice-message {
  color: white !important;
}

.ant-popover-inner-content {
  color: white !important;
  background-color: #2c2c2c;
  box-shadow: #000;
  border-radius: 3px;
}

.ant-popover-message > .anticon {
  position: absolute;
  color: #faad14;
  top: 3px;
  font-size: 14px;
}

.css-yk16xz-control {
  background: transparent !important;
}

.css-1uccc91-singleValue {
  color: white !important;
}

.ant-modal-footer {
  border-top: none;
}

.ant-modal-title {
  margin: 0;
  color: #fff;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}

.ant-modal-content {
  position: relative;
  background-color: #000;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  pointer-events: auto;
  width: 200%;
  min-height: 50vh;
  margin-left: -250px;
}

.slide-text-section {
  position: relative;
  top: -54px;
  background-color: #fff;
  padding-bottom: 10px;
}

/*Zuhaib Changes*/
.itemContainer {
  height: fit-content;
  width: fit-content;
  padding: 2rem;
  height: 100%;
  margin: auto;


  h4 {
    font-size: 3rem;
  }

  p {
    font-size: 1.7rem;
    margin: 0;
    color: rgb(145, 143, 143);
  }

  a {
    display: block;
    padding: 1rem;
    font-size: 2rem;
    font-weight: bold;
  }

  .Clock-days {
    font-weight: bold;
    font-size: 2.5rem!important;
  }

  .price {
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 10px;
    line-height: 1.1;
  }
}

.leftImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icon {
  width: 20rem;
  margin-bottom: 2rem;
}

.rightColumn {
  align-items: center;
  text-align-last: center;
}

.modalbutton {
  text-decoration: none;
  // color: #101f3e;
  font-size: 12px;
  font-weight: bold;
}

.alreadyAccount {
  font-size: 12px;
  // color: grey;
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .itemContainer {
    height: 37rem;
  }

  .leftImage {
    width: 33rem;
  }
}

.itemContainerDetail {
  height: auto;
  width: 100%;
  padding: 2rem;
  margin: auto;


  .itemContainerDescription {
    // color: #303133;
  }
}

.rightColumnItemDetail {
  text-align: left;
}

.DynamicButton {
  display: block;
  // padding: 1rem;
  font-size: 1.7rem;
  font-weight: 700 !important;
}

// .DynamicButton:hover {
//   display: block;
//   // padding: 1rem;
//   opacity: 0.8;
//   font-size: 1.7rem;
//   font-weight: bold;
// }
.DynamicText {
  font-size: 3rem;
  margin-top: 10px;
}

.DynamicVideo {
  max-width: 83%;
  margin-left: -5rem;
}

.DynamicAddCard {
  padding: 10rem;
}

.sold_button_check {
  opacity: 0.4;
}

.marketplace__item {
  // background-color: #fff;

  h4 {
    font-size: 3rem;
    margin-top: 10px;
  }

  p,
  .p {
    font-size: 2rem;
    margin: 0;
    color: rgb(145, 143, 143);
  }

  a {
    display: block;
    padding: 1rem;

    font-weight: bold;
  }

  .Clock-days {
    font-weight: bold;
    font-size: 2rem;
  }

  .price {
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 10px;
    line-height: 1.1;
  }
}
.ant-dropdown-menu-vertical {
  width: 100% !important;
  overflow: scroll !important;
  .row{
    display: flex;
    align-items: center;
  }
  input{
    width: 20px!important;
  }
}
/*Zuhaib Changes End*/
.video-sec {
  // padding-right:80px;
  background-color: #000;
}

.video-text-sect {
  padding-left: 80px;
}

.sort-search {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 50px;
}
.popup-btn:after {
  content: '';
  width: 100%;
  border-bottom: 1px solid #252525;
  display: block;
  position: absolute;
  margin-left: 18px;
}
.nav-item {
  background-color: #015fbc!important;
  border-radius: 10px;
  color: #ffffff !important;

  font-size: 16px !important;
}
.nav-link {
  padding: 10px 14px !important;
  font-size: 16px !important;
  color:#fff!important;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #272727 !important;
  border-radius: 10px;
  background-color: white !important;
  border-color: transparent;
  padding: 10px 14px !important;
}


/*Início das configurações da Navigation com dispositivos < 768px*/
@media screen and (min-width: 768px) {
  .slide-text-section {
    position: relative;
    top: 0;
    background-color: #fff;
    padding-bottom: 10px;
  }

  //  .column-reverse{
  //   flex-direction: column-reverse!important;
  // }
  .recaptcha .recaptcha-layout-size div div{
    text-align: left;
    margin-left: -23px;
  }
  .recaptcha{
    margin-top: -10px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 500px) {
  .notify-me {
    // margin-top: 20rem !important;
  }
.detail-heading.heading-break .word:nth-child(2) {
  display: block;
}
}

@media screen and (max-width: 400px) {
  /*Zuhaib Changes*/

  .pageLayout__grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 360px));
    justify-content: center;
    column-gap: 35px;
    row-gap: 45px;
    padding: 5px;
    margin: -5px auto 0;
    max-width: 1200px;
  }

  .sold_button_check {
    opacity: 0.4;
  }

  .marketplace__item {
    // background-color: #fff;

    h4 {
      font-size: 2rem;
      margin-top: 10px;
    }

    p,
    .p {
      font-size: 2rem;
      margin: 0;
      color: rgb(145, 143, 143);
    }

    a {
      display: block;
      padding: 1rem;

      font-weight: bold;
    }

    .Clock-days {
      font-weight: bold;
      font-size: 2rem;
    }

    .price {
      font-size: 2rem;
      font-weight: bold;
      margin-top: 10px;
      line-height: 1.1;
    }
  }

  .DynamicVideo {
    max-width: 83%;
    margin-left: 0rem;
  }

  .DynamicButton {
    display: block;
    padding: 1rem;
    font-size: 1rem;
    font-weight: bold;
  }

  .DynamicText {
    font-size: 1.8rem;
    margin-top: 10px;
  }

  .DynamicAddCard {
    padding: 2rem;
  }

  .itemContainer {
    height: fit-content;
    width: fit-content;
    padding: 2rem;
    height: 100%;
    margin: auto;


    h4 {
      font-size: 3rem;
    }

    p {
      font-size: 1.7rem;
      margin: 0;
      color: rgb(145, 143, 143);
    }

    a {
      display: block;
      padding: 1rem;
      font-size: 2rem;
      font-weight: bold;
    }

    .Clock-days {
      font-weight: bold;
      font-size: 2rem;
    }

    .price {
      font-size: 2.5rem;
      font-weight: bold;
      margin-top: 10px;
      line-height: 1.1;
    }
  }

  .leftImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .icon {
    width: 20rem;
    margin-bottom: 2rem;
  }

  .rightColumn {
    align-items: center;
    text-align-last: center;
  }

  @media only screen and (min-width: 600px) {
    .itemContainer {
      height: 37rem;
    }

    .leftImage {
      width: 33rem;
    }
  }

  .itemContainerDetail {
    height: auto;
    width: 100%;
    padding: 2rem;
    margin: auto;


    .itemContainerDescription {
      // color: #303133;
    }
  }

  .rightColumnItemDetail {
    text-align: left;
  }

  /*Zuhaib Changes End*/

  .ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    pointer-events: auto;
    width: 90%;
    // min-height: 10vh;
    margin-left: 20px;
  }




  .carousel-indicators {
    margin: -30px;
  }

  .loading-spinner-initial {
    text-align: center;
    margin-top: -100px;
  }

  .inner-card {
    margin-top: -15px;
  }

  .slider-image {
    margin-bottom: 1.5rem !important;
  }

  .inner-card-body {
    margin-top: -5px;
    padding-bottom: 180px;
    box-sizing: content-box;
    width: 140%;
    border-radius: 50%;
    margin-left: -60px;
  }

  .logo-style {
    margin-top: -35px;
  }

  .dropdown {
    width: 100%;
  }

  .dropdown:hover {
    height: 10vh;
    width: 100%;
  }

  .dropdown-content {
    margin: revert;
    margin-bottom: 10px;
  }

  .dropdown-content.lists {
    margin-left: 0px;
  }

  .dropdown-content a.bar {
    // color: white;
    text-decoration: none;
    display: block;
    border-bottom: white 1px solid;
    max-height: 55px;
  }

  .custom-select {
    width: 100%;
  }

  .dropdown-content a.bar:hover {
    // background-color: white;
    // color: white;
  }

  .menu {
    // width: 100%;
    height: auto;
  }

  .menu ul {
    display: block;
    max-height: 0;
    width: 100%;
    overflow-x: hidden;
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    -ms-transition: max-height 0.3s;
    -o-transition: max-height 0.3s;
    transition: max-height 0.3s;
  }

  .menu ul a {
    text-align: center;
    width: 100%;
    height: auto;
    margin: revert;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    background-color: #272727;
    margin-left: 0px;
  }

  .menu-icon {
    width: 100px;
    height: inherit;
    font-size: 16px;
    display: block;
    position: absolute;
    color: White;
    top: 0;
    right: 0;
    margin-top: 20px;
    line-height: 60px;
  }

  #menuToggle:checked ~ ul {
    max-height: 350px;
    /*Para fazer com que o menu saia da esquerda ou direita, você deve trocar max-height por max-width*/
  }

  .menu-icon i {
    font-size: 1.7em;
  }
}

.column-reverse {
  flex-direction: row;
}

.my-portfolio {
  text-align: start;
}

.sort-dropdown {
  text-align: end;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
}

.sort__dowm {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-top: 0;
}

.footer_rows {
  padding: 3rem 0rem!important;
}

.ul_styles_icons {
  padding-top: 0;
}

.footer-menu-right {
  justify-content: end;
}

.learn-more-text {
  padding: 0;
}

.learn-date-name {
  text-align: end;
  padding: 0;
}

.sort-search-main {
  display: flex;
  align-items: center;
  justify-content: end;
}

.sort-search {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding-top: 10px;
}

.detail-heading {
  text-align: start;
}

.collection-heading-margin {
  margin: 5rem 22rem;
}

.top-ten-heading {
  text-align: start;
}
.video-metabila {
  height: 500px;
}
// .headernewdark {
//  color: #252525!important;
//  background: #fff!important;
// }
// #headerBgColor {
//   height: 170px !important;
// }
@media screen and (max-width: 1300px) {
  .footer-menu-responsive,
  .footer-menu,
  .footer-menu-icons,
  .footer-menu-right {
    justify-content: center !important;
    margin: 0 !important;
    padding-bottom: 10px !important;
  }
  .responsive-menu-ul {
    position: absolute !important;
    z-index: 9999999;
 
    width: 50% !important;
    right: 0 !important;
    top: 60px !important;
  }
  .inner-responsive-menu-ul {
    position: absolute !important;
    z-index: 9999999;
    width: 50% !important;
    right: 0 !important;
    top: 60px !important;
    height: 250px !important;
  }
}
input {
  background-color:#fff  !important;
  border: 0 !important;
  padding: 10px !important;
  width: 100% !important;
  height: 4em !important;
  border-radius: 10px !important;
}
.stripe-form-padding {
  padding: 0 50px;
}
.date-picker-edit-modal {
  input {
 
    border: 0 !important;
 
    padding: 10px !important;
    width: 100% !important;
    height: 4em !important;
    border-radius: 8px !important;
  }
  .ant-calendar-picker-icon {
    color: #fff !important;
  }
}
.signInsignUpTabs {
  text-align: center;
  br{
    display: none;
  }
}
.athlete-partner{
  .pointer{
    pointer-events: none;
  }
  .slide-text{
    color:#fff!important;
  }
}
.main-slider-btn{
  margin-top:30px;

}
.itemdetail-margin-t{
  margin-top: 40px;
}
.inner-product-page{
   padding-top: 50px;
  padding-bottom: 50px;
}
.title-font-size{
        font-size: 20px;
  }
   .curved-img{
    display: block;
  }
  .pa-t-40{
    padding:80px;
  }
  .i-font{
    font-size: 50px;
  }
  .mt-150{
    margin-top: 150px;
  }
  .border-right-1px{
    border-right: 2px solid #4E4E4E;
  }
  .max-bid-input input{
    height: 2.5em!important;
    color: #000!important;
    padding-left: 45px!important;
    border-radius: 4px !important;
  }
  .max-bid-input .dollar-sign{
    color: #000!important;
  }
  .max-bid-input .login-form h2{
    margin-top: 0!important;
  }
  .menu-dropdown{
    left: -85px!important;
  }
.ant-dropdown-menu-vertical{
  height: 500px!important;
}
.itemdetail-margin-t{
  margin-top: 40px;
}
.inner-product-page{
   padding-top: 50px;
  padding-bottom: 50px;
}
.title-font-size{
        font-size: 20px;
  }
   .curved-img{
    display: block;
  }
  .pa-t-40{
    padding:80px;
  }
  .i-font{
    font-size: 50px;
  }
  .mt-150{
    margin-top: 150px;
  }
  .border-right-1px{
    border-right: 2px solid #4E4E4E;
  }
  .max-bid-input input{
    height: 2.5em!important;
    color: #000!important;
    padding-left: 45px!important;
    border-radius: 4px !important;
  }
  .max-bid-input .dollar-sign{
    color: #000!important;
  }
  .max-bid-input .login-form h2{
    margin-top: 0!important;
  }
  .responsive-dropdown
  {
    
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  /* Make the overlay transparent */
.offcanvas-backdrop::before {
    background-color: transparent;
}

/* Or remove the overlay completely */
.offcanvas-backdrop::before {
    content: none;
}
.offcanvas-end{
  width: 100%!important;
}
.padding-0-dropdown{
  padding:0!important;
}
.ol-font-size {
  width:300px;
  min-width:100%;
  border-radius:10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
  li{
  padding: 5px 0!important;
}
  button{
  font-family: "Montserrat" !important;
  font-size:18px!important;
  font-weight: 600!important;
}
}

.desktop-menu{
  display:block;
}
.mobile-menu{
  display:none;
}
.desktop-menu-ul{
  display: flex;
  justify-content: end;
  gap: 30px;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
}
.dropdowmn-second{
    button{
  font-family: "Montserrat" !important;
  font-size:18px!important;
  font-weight: 600!important;
}
}
 .nav-item {
    background-color: transparent!important;}
    .react-datepicker__year-dropdown-container--scroll{
      font-size: 14px!important;
    }
.react-datepicker__year-read-view--down-arrow{
  top:5px!important;
}
.react-datepicker__year-dropdown-container--scroll{
  height: 40px!important;
}
.react-datepicker__week{
  height: 30px!important;
}
@media screen and (max-width: 768px) {
  .navbar-toggler {
  z-index: 1000; /* Ensure it is above other elements */
  border: none; /* Remove any border that might interfere */
  background: transparent; /* Transparent background for better UX */
  padding: 10px; /* Increase the clickable area */
}

.recaptcha {
  margin-bottom: 40px;
  margin-top: -10px;
  margin-left: -5px;
}

.fa-bars {
  pointer-events: none; /* Ensure the icon itself does not block clicks */
}
  .desktop-menu{
  display:none!important;
}
.mobile-menu{
  display:block!important;
}
  .nav-item {
    background-color: transparent!important;}
  .menunewdark ul a{
    text-align: start!important;
    font-size: 18px!important;
  }
   .responsive-dropdown
  {
   left:unset!important;
    box-shadow:unset!important;
    top: 30px!important;
  }
  .menu-dropdown button{
    line-height: 25px!important;
  }
  .redemp-b{
    padding-bottom:30px;
  }
  .modal-header .close{
    margin-top: 15px!important;   
  }
  .history-gap{
    gap:10px!important;
  }
  .border-right-1px{
    border-right: 0!important;
  }
  .video-text-sect{
    padding-top: 20px;
  }
    .mt-150{
    margin-top: 30px!important;
  }
   .i-font{
    font-size: 30px!important;
  }
  .pa-t-40{
    padding:0!important;
  }
  .curved-img{
    display: none!important;
  }
  .title-font-size{
        font-size: 30px !important;
  }
  .itemdetail-margin-t{
  margin-top: 0px!important;
  padding: 0!important;
}
.inner-product-page{
   padding: 0!important;
 
}
  .main-slider-btn{
  margin-top:0px!important;
}
    .leaderboard-center{
      overflow: hidden;
    .ant-table-wrapper{
      width: 100%!important;
    }
    .ant-table-thead tr th{
      padding:20px 0!important;
      font-size:12px!important;
      
      
    }
  }
  #new-filter-design{
    width: 200px!important;
    max-width: 100%!important;
  }
  h1{
    font-size:20px!important;
  }
  h2,h3{
    font-size:20px!important;
  }
  .darkdropdownnew-content{
    width: 100%!important;
  }
  .platform-text{
  padding: 50px 50px 50px!important;
}
  .responsive-top-mr{
margin-top: 5rem!important;
  }
.detail-heading{
  font-size: 20px!important;
  font-weight: 700!important;
  margin-bottom: 50px;
}
// .detail-heading.heading-break{
//   word-break: keep-all !important;
//   white-space: normal !important;
//   overflow-wrap: break-word;
//   hyphens: none;
// }
.current-activity-margins{
  margin-left: -14px;
  margin-right: -14px;
}
  .atm-badge{
    width:65px;
  }
  .analytics-form {
    margin-left: 530px;
  }
  .sort-search {
    display: flex;
   justify-content: space-around;
   padding-top: 15px;
   flex-wrap: wrap;
   margin: 0!important;
    h2 {
      padding-bottom: 0px;
    }
  }
  .ul_styles_icons {
    margin: auto !important;
  }
  .itemContainerDescription {
    text-align: justify;
  }
  .footer-menu-responsive {
    text-align: left !important;
  }
  .dropdown-content .bar {
    margin: auto !important;
  }
  .barnew {
    margin: auto !important;
  }
  .footer-menu {
    justify-content: start !important;
  }
  .zig-zag-mob div {
    text-align: center !important;
    word-break: break-word!important;

  }
  .stripe-form-padding {
    padding: 0 0 !important;
  }
  .labeltext {
    margin-right: 40%;
  }

  .labeltext1 {
    margin-right: 70%;
  }
  .modal-content {
    padding: 10px !important;
  }
  .redeem-steps .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    display: block;
    min-height: 0px !important;
    overflow: unset !important;
  }
  .redeem-steps .ant-steps-label-horizontal {
    overflow: scroll;
  }
  .footer-menu-responsive,
  .footer-menu-right,
  .footer-menu-icons {
    justify-content: center !important;
    margin: 0 !important;
    padding-bottom: 10px !important;
  }
  .responsive-menu-ul {
    position: absolute !important;
    z-index: 9999999;
  
    width: 100% !important;
    right: 0 !important;
    top: 60px !important;
    height: 250px !important;
  }
  .inner-responsive-menu-ul {
    position: unset !important;
    z-index: 9999999;
    width: auto !important;
    right: 0 !important;
    top: 160px !important;
    height:100vh!important;
    max-height:100vh!important;
  }
  .burger-menu {
    position: relative;
    z-index: 99999;
    background-color: #272727;
  }
  .scroll-tab {
    width: 180%;
    overflow-x: scroll !important;
  }
.signInsignUpTabs {
    margin:0!important;
    width: 100%;
    overflow-x: hidden !important;
    padding: 10px 0!important;
    h6{
      font-size:10px!important;
      font-weight:300!important;
    }
      br{
    display: block!important;
  }

  }
  .signInsignUpTabs img {
    left: 50px !important;
  }
  // .headernewdark {
  //   height: 200px !important;
  // }
  // .headernewdark .popup-btn::after {
  //   display: none;
  // }
  .popup-btn:after {
    content: '';
    width: 100%;
    border-bottom: 2px solid #d9d9d9;
    display: block;
    position: absolute;
    margin-left: 18px;
  }
  .marginleft-mission {
    margin-left: 0px !important;
  }
  .margintop-equality {
    margin-top: 0px !important;
  }
  .video-metabila {
    width: 100% !important;
    height: auto;
  }
  .carousel-indicators {
    bottom: 35px !important;
  }
  .linebreak-none br {
    display: none;
  }
  .responsive-padding {
    padding: 0 20px;
  }
  .top-ten-heading {
    text-align: center !important;
  }

  .table {
    overflow: scroll;
  }

  .gap--0 {
    gap: 10px !important;
  }

  .eventsContainerConditional {
    padding: 0 10px !important;
    grid-template-columns: 3fr !important;
  }

  .detail-heading {
    text-align: center !important;
  }

  .collection-heading-margin {
    margin: 5rem 0 !important;
  }

  .control-next {
    right: 10px !important;
    z-index: 2;
  }

  .control-prev {
    left: 10px !important;
    z-index: 2;
  }

  .sort-search-main {
    display: contents !important;
  }

  .inner-product-page {
    display: inline-block !important;
    padding-bottom: 0!important;
    width: 100%!important;
    h3{
      word-break: break-word!important;
      text-align: center!important;
    }
  }

  .sort__dowm {
    padding-top: 20px;
    justify-content: center !important;
  }

  .my-portfolio {
    text-align: center;
  }

  .video-sec {
    padding-right: 0;
  }

  .video-text-sect {
    padding-left: 0;
  }

  .footer_rows {
    padding: 3rem 0 !important;
  }

  .ul_styles_icons {
    padding-top: 30px;
  }

  .footer-menu-right {
    justify-content: center;
  }

  .learn-more-text {
    padding: 0 3rem;
  }

  .learn-date-name {
    text-align: center !important;
    padding: 3rem 0;
  }

  .ftr-center {
    justify-content: center !important;

    .ul_styles {
      gap: 35px !important;
    }
  }

  .footer-menu-right {
    justify-content: center !important;
  }

  .column-reverse {
    flex-direction: column-reverse;
  }

  .gap-tech {
    gap: 50px 140px !important;
  }

  /*Zuhaib Changes*/

  .mobile-text {
    font-size: 14px !important;
  }

  .play_button {
    font-size: 50px;
    position: absolute;
    color: white;
    left: 46%;
    top: 22%;
    z-index: 1200;
  }

  .mute_button {
    font-size: 24px;
    position: absolute;
    top: 74%;
    left: 11%;
    color: white !important;
    z-index: 1200;
    cursor: pointer;
  }

  .video_main_page {
    max-width: 84%;
    margin-top: 0;
    margin-bottom: 5%;
    margin-left: 0px;
  }

  .featured_video_adjustment {
    position: relative;
    top: 0;
  }

  .modalbutton {
    text-decoration: none;
    // color: #101f3e;
    font-size: 12px;
    font-weight: bold;
  }

  .alreadyAccount {
    font-size: 12px;
    // color: grey;
    text-align: center;
  }

  .sold_button_check {
    opacity: 0.4;
  }

  .marketplace__item {
    // background-color: #fff;

    h4 {
      font-size: 2rem;
      margin-top: 10px;
    }

    p,
    .p {
      font-size: 2rem;
      margin: 0;
      color: rgb(145, 143, 143);
    }

    a {
      display: block;
      padding: 1rem;

      font-weight: bold;
    }

    .Clock-days {
      font-weight: bold;
      font-size: 2.5rem!important;
    }

    .price {
      font-size: 2rem;
      font-weight: bold;
      margin-top: 10px;
      line-height: 1.1;
    }
  }

  .DynamicVideo {
    max-width: 100%;
    margin-left: 0rem;
  }

  .DynamicText {
    font-size: 1.8rem;
    margin-top: 10px;
  }

  .DynamicAddCard {
    padding: 2rem;
  }

  .itemContainer {
    height: fit-content;
    width: fit-content;
    padding: 2rem;
    height: 100%;
    margin: auto;
 

    h4 {
      font-size: 3rem;
    }

    p {
      font-size: 1.7rem;
      margin: 0;
      color: rgb(145, 143, 143);
    }

    a {
      display: block;
      padding: 1rem;
      font-size: 2rem;
      font-weight: bold;
    }

    .Clock-days {
      font-weight: bold;
      font-size: 2rem;
    }

    .price {
      font-size: 2.5rem;
      font-weight: bold;
      margin-top: 10px;
      line-height: 1.1;
    }
  }

  .leftImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .icon {
    width: 20rem;
    margin-bottom: 2rem;
  }

  .rightColumn {
    align-items: center;
    text-align-last: center;
  }

  @media only screen and (min-width: 600px) {
    .itemContainer {
      height: 37rem;
    }

    .leftImage {
      width: 33rem;
    }
  }

  .itemContainerDetail {
    height: auto;
    width: 100%;
    padding: 2rem;
    margin: auto;
 

    .itemContainerDescription {
      // color: #303133;
    }
  }

  .rightColumnItemDetail {
    text-align: left;
  }

  /*Zuhaib Changes End*/

  .ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    pointer-events: auto;
    width: 90%;
    // min-height: 10vh;
    margin-left: 20px;
  }




  .carousel-indicators {
    margin: -30px;
  }

  .loading-spinner-initial {
    text-align: center;
    margin-top: -100px;
  }

  .inner-card {
    margin-top: -15px;
  }

  .slider-image {
    margin-bottom: 1.5rem !important;
  }

  .inner-card-body {
    margin-top: -5px;
    padding-bottom: 180px;
    box-sizing: content-box;
    width: 140%;
    border-radius: 50%;
    margin-left: -60px;
  }

  .logo-style {
    margin-top: -35px;
  }

  .dropdown {
    width: 100%;
  }

  .dropdown:hover {
    height: 10vh;
    width: 100%;
  }

  .dropdown-content {
    margin: revert;
    margin-bottom: 10px;
  }

  .dropdown-content.lists {
    margin-left: 0px;
  }

  .dropdown-content a.bar {
    // color: white;
    text-decoration: none;
    display: block;
    border-bottom: white 1px solid;
    max-height: 55px;
  }

  .custom-select {
    width: 100%;
  }

  // .dropdown-content a.bar:hover {
  //   // background-color: white;
  //   // color: white;
  // }

  .menu {
    width: 100%;
    height: auto;
  }

  .menu ul {
    display: block;
    max-height: 0;
    width: 100%;
    overflow-x: hidden;
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    -ms-transition: max-height 0.3s;
    -o-transition: max-height 0.3s;
    transition: max-height 0.3s;
  }

  .menu ul a {
    text-align: center;
    width: 100%;
    height: auto;
    margin: revert;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    background-color: #272727;
    margin-left: 0px;
  }

  // .menu-icon {
  //   width: 100px;
  //   height: inherit;
  //   font-size: 16px;
  //   display: block;
  //   position: absolute;
  //   color: White;
  //   top: 0;
  //   right: 0;
  //   margin-top: 20px;
  //   line-height: 60px;
  // }

  .menu-icon {
    width: 100px;
    height: inherit;
    font-size: 16px;
    display: block;
    position: absolute;
    color: White;
    top: -87px;
    right: 0;
    line-height: 60px;
  }

  #menuToggle:checked ~ ul {
    max-height: 350px;
    /*Para fazer com que o menu saia da esquerda ou direita, você deve trocar max-height por max-width*/
  }

  .menu-icon i {
    font-size: 1.7em;
  }
  .items-alignment{
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  #new-filter-design{
    width: 400px!important;
    max-width: 100%!important;
  }
  .stripe-form-padding{
    padding:0!important;
  }
  .responsive-cards{
    flex-direction: unset!important;
  }
  .ftr-center .ul_styles{
    flex-wrap: wrap!important;
    gap:15px!important;
  }
  .footer-menu-responsive{
    flex-wrap: wrap!important;
  }
  .ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    pointer-events: auto;
    width: 90%;
    // min-height: 10vh;
    margin-left: 20px;
  }





  .carousel-indicators {
    margin: -30px;
  }

  .loading-spinner-initial {
    text-align: center;
    margin-top: -100px;
  }

  .inner-card {
    margin-top: -15px;
  }

  .inner-card-body {
    margin-top: -5px;
    padding-bottom: 180px;
    box-sizing: content-box;
    width: 140%;
    border-radius: 50%;
    margin-left: -60px;
  }

  .logo-style {
    margin-top: -35px;
  }

  .dropdown {
    width: 100%;
  }

  .dropdown:hover {
    height: 10vh;
    width: 100%;
  }

  .dropdown-content {
    margin: revert;
    margin-bottom: 10px;
  }

  .dropdown-content.lists {
    margin-left: 0px;
  }

  .dropdown-content a.bar {
    // color: white;
    text-decoration: none;
    display: block;
    border-bottom: white 1px solid;
    width: 42%;
    // height: 8vh;
  }

  .custom-select {
    width: 100%;
  }

  .dropdown-content a.bar:hover {
    // background-color: white;
    color: white;
  }

  .menu {
    width: 100%;
    height: auto;
  }

  .menu ul {
    // margin-top: 14px;
    display: block;
    max-height: 0;
    width: 100%;
    overflow-x: hidden;
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    -ms-transition: max-height 0.3s;
    -o-transition: max-height 0.3s;
    transition: max-height 0.3s;
  }

  .menu ul a {
    text-align: center;
    width: 100%;
    height: auto;
    margin: 0px;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    background-color: #272727;
    margin-left: 0px;
  }

  .menu-icon {
    width: 100px;
    height: inherit;
    font-size: 16px;
    display: block;
    position: absolute;
    color: White;
    top: 0;
    right: 0;
    margin-top: 20px;
    line-height: 60px;
  }

  #menuToggle:checked ~ ul {
    max-height: 350px;
    /*Para fazer com que o menu saia da esquerda ou direita, você deve trocar max-height por max-width*/
  }

  .menu-icon i {
    font-size: 1.7em;
  }
}

@media screen and (max-width: 1330px) {
  /*Zuhaib Changes*/

  /*Zuhaib Changes End*/

  /*Basit*/
  .auction-detail-page {
    height: auto !important;
    margin: 0;
  }

  .auto-box-new {
    margin: 20px !important;
  }

  .carousel-arrow-slide {
    margin: 3rem !important;
  }

  /*end*/
  .ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    pointer-events: auto;
    width: 90%;
    // min-height: 10vh;
    margin-left: 20px;
  }

 

 
  .carousel-indicators {
    margin: 0;
    position: relative;
  }

  .loading-spinner-initial {
    text-align: center;
    margin-top: -100px;
  }

  .inner-card {
    margin-top: -15px;
  }

  .inner-card-body {
    margin-top: -5px;
    padding-bottom: 192px;
    box-sizing: content-box;
    width: 140%;
    border-radius: 50%;
    margin-left: 0;
    height: 490px !important;
  }

  .logo-style {
    margin-top: -18px;
  }

  .dropdown {
    width: 100%;
  }

  .dropdown:hover {
    height: 10vh;
    width: 100%;
  }

  .dropdown-content {
    margin: revert;
    margin-bottom: 10px;
  }

  .dropdown-content.lists {
    margin-left: 0px;
  }

  .dropdown-content a.bar {
    color: white;
    text-decoration: none;
    display: block;
    border-bottom: white 1px solid;
    width: 42%;
    // height: 16vh;
  }

  .custom-select {
    width: 100%;
  }

  .dropdown-content a.bar:hover {
    // background-color: white;
    color: white;
  }

  .menu {
    width: 100%;
    height: auto;
  }

  .menu ul {
    // margin-top: 14px;
    display: block;
    max-height: 0;
    position: relative;
    top: 3px;
    width: 100%;

    overflow-x: hidden;
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    -ms-transition: max-height 0.3s;
    -o-transition: max-height 0.3s;
    transition: max-height 0.3s;
  }

  .menu ul a {
    text-align: center;
    width: 100%;
    height: auto;
    margin: 0px;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    background-color: #272727;
    margin-left: 0px;
  }

  header {
    // background: #2c2c2c;
    width: 100%;
    z-index: 10;
    position: relative;
    line-height: 50px;
    text-align: center;
  }

  .menu-icon {
    width: 100px;
    height: inherit;
    font-size: 16px;
    display: block;
    position: absolute;
    color: White;
    // bottom: 4px;
    right: 0;
    top: 10px !important;
    // margin-top: 20px;
    line-height: 60px;
  }

  #menuToggle:checked ~ ul {
    max-height: 350px;
    /*Para fazer com que o menu saia da esquerda ou direita, você deve trocar max-height por max-width*/
  }

  .menu-icon i {
    font-size: 1.7em;
  }

  .justify-content-start {
    height: 300px;
  }

  .brand {
    width: 60px !important;
  }

  // .img-box {
  //   height: 230px !important;
  //   margin-left: 0 !important;
  //   overflow: hidden;
  // }
  // .img-box-row {
  //   width: 340px !important;
  //   height: 210px !important;
  // }
  .img-box-col-12 {
    overflow: hidden !important;
  }

  .img-box img {
    margin-left: 0px !important;
    height: 100% !important;
  }

  .border-right {
    padding-right: 0 !important;
    border-right: 0px !important;
    border-left: 0px !important;
  }

  .borders {
    border-top: 0px !important;
    border-bottom: 0px !important;
  }

  .border-image {
    border: 2px solid #ddd !important;
    padding: 0px !important;
  }

  .profile-btn-last {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .react-datepicker__navigation-icon{
    top: 10px !important;
  }
  .react-datepicker__year-read-view--down-arrow{
    top: 18px !important;
  }
}

/*FeaturedCollection*/
.btn-floating {
  font-size: 20px;
  color: #fff;
  background-color: #000;
  padding: 6px 16px;
  border-radius: 100%;
}

.control-prev {
  float: left;
  position: absolute;
  top: 40%;
  left: -50px;
}

.control-next {
  float: right;
  position: absolute;
  top: 40%;
  right: -50px;
}

.athelte-pip-img {
  img {
    object-fit: contain !important;
  }
}

.proud-partner {
  img {
    object-fit: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .footer-menu-responsive {
    flex-direction: column !important;
  }

  .footer-menu {
    justify-content: center;
  }

  .responsive-text {
    h3 {
      font-size: 14px !important;
    }
  }
}

.hwrap {
  //   /* (A1) DIMENSIONS */
  //   width: 100%;
  //   height: 150px; /* OPTIONAL */

  //   /* (A2) COSMETICS */

  overflow: hidden;
  /* HIDE SCROLLBARS */
}

/* (B) MIDDLE CONTAINER - FLEX LAYOUT */
.hmove {
  display: flex;
  position: relative;
  top: 0;
  right: 0;
}

// /* (C) SLIDES - FORCE INTO ONE LONG HORIZONTAL ROW */
.hslide {
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 10px 0 10px 0;
}

/* (D) SLIDE ANIMATION */
@keyframes slideh {
  /* (D0) THE IDEA - USE KEYFRAMES TO SHIFT SLIDES *
  0% { right: 0; }
  33% { right: 100%; }
  66% { right: 200%; }
  100% { right: 0; }

  /* (D1) BUT THE ABOVE WILL SHIFT NON-STOP */
  /* SO WE ADD PAUSES BETWEEN EACH SLIDE */
  0% {
    right: 0;
  }

  30% {
    right: 0;
  }

  33% {
    right: 100%;
  }

  63% {
    right: 100%;
  }

  66% {
    right: 200%;
  }

  97% {
    right: 200%;
  }

  100% {
    right: 0;
  }
}

.hmove {
  animation: slideh linear 15s infinite;
}

.hmove:hover {
  animation-play-state: paused;
}

// .modal-content {
//   background-color: #252525;
// }

input.form-control::placeholder {
  color: #858585;
  font-size: 14px;
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1200px;
    align-items: normal !important;
  }
}

/*ab*/
.footer-menu {
  ul {
    display: flex !important;
    list-style: none !important;
    gap: 40px;

    li {
      margin-right: 10px;
      list-style-type: none;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.footer-menu-right {
  ul {
    display: flex !important;
    list-style: none !important;
    gap: 20px;

    li {
      list-style-type: none;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.footer-menu-icons {
  ul {
    display: flex !important;
    list-style: none !important;
    gap: 15px !important;
    align-items: center;
  }
}

.ant-modal-body,
.ant-modal-header,
.ant-modal-footer,
.ant-modal-content {
  background-color: #252525;
}

.card-body {
  box-shadow: none !important;
}

.card {
  background-color: transparent;
  border: none !important;
}

.ant-select-selection--single {
  background: transparent;
  border: 2px solid #969696;
  color: white;
  padding: 10px;
  width: 100%;
  height: 4em;
  border-radius: 8px;
}

.ant-btn {
  background-color: #385174 !important;
  color: #fff !important;
  border: 0 !important;
}
.redeem-steps{
  padding-bottom: 70px;
  input{
    border:1px solid #2c2c2c!important;
    border-radius: 10px!important;
  }
}
.copy-rights{
  font-size:16px!important;
}
.save-cards{
  font-size:12px!important;
}
.item-dropdown {
  width: 30px !important;
  .dropdown-menu {
    transform: translate(-97px, 65px) !important;
  }
  .dropdown-menu1 {
    transform: translate(-205px, 65px) !important;
  }

  .myitem-btndropdown {
    transform: rotate(90deg);
    height: 5px;
    font-size: 40px;
    background-color: transparent !important;
    border: none !important;
    height: 90px;
    width: 100%;

    &::after {
      display: none;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

.payment-info-table {
  th {
    border-top: 2px solid #4e4e4e !important;
  }

  td {
    border-top: 2px solid #4e4e4e !important;
    border-bottom: 2px solid #4e4e4e !important;
  }

  tr {
    border-top: 2px solid #4e4e4e !important;
  }

  thead {
    th {
      border-bottom: 2px solid #4e4e4e !important;
    }
  }
}

.flag-dropdown {
  background-color: transparent !important;
}

.gap-tech {
  gap: 40px 100px;
}

// .ant-dropdown-menu {
//   background-color: #272727;

//   h2 {
//     color: #fff !important;
//   }

//   h4 {
//     color: #fff !important;
//   }
// }



.ant-popover-message-title {
  color: #fff !important;

  h4 {
    color: #fff !important;
  }
}

.footer-menu-responsive {
  .li_styles {
    &:hover {
      margin-right: 10px !important;
      font-weight: 400 !important;
    }
  }
}
.aboutus-carousel {
  .carousel-indicators {
    bottom: -20px !important;
  }
}

.margintop-equality {
  margin-top: -100px;
}
.marginleft-mission {
  margin-left: 100px;
}
// Flip
.flip-box {
  background-color: transparent;
  width: 280px;
  height: 280px;
  // perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  background-color: #bbb;
  color: black;
}

.flip-box-back {
  background-color: #555;
  color: white;
  transform: rotateY(180deg);
}
//
.team-names {
  position: relative;
  display: flex;
  justify-content: center;
}
.team-text {
  position: absolute;
  bottom: 5px;
  text-align: center;
  p {
    color: #fff;
    font-size: 16px;
    margin: 0;
  }
}

.fade-in-image {
  animation: fadeIn ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.bg-gradient {
  background: rgb(31, 60, 72) !important;
  background: -moz-linear-gradient(90deg, rgba(31, 60, 72, 0.8718837876947654) 44%, rgba(0, 0, 0, 0) 100%) !important;
  background: -webkit-linear-gradient(
    90deg,
    rgba(31, 60, 72, 0.8718837876947654) 44%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
  background: linear-gradient(90deg, rgba(31, 60, 72, 0.8718837876947654) 44%, rgba(0, 0, 0, 0) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1f3c48",endColorstr="#000000",GradientType=1) !important;
}

.progress-value {
  animation: load 4s normal forwards;
  top: 0;
  position: inherit;
  padding-left: 20px;
  border-left: 15px solid #2283ad;
  height: 0;
  text-align: start;
}
@keyframes load {
  0% {
    height: 0;
  }
  100% {
    height: 33%;
  }
}
.progress-value-passion {
  animation: passion 5s normal forwards;
  top: 0;
  position: inherit;
  padding-left: 20px;
  border-left: 15px solid #2283ad;
  height: 0;
  text-align: start;
}
@keyframes passion {
  0% {
    height: 0;
  }
  100% {
    height: 41%;
  }
}
.progress-value-equality {
  animation: equality 6s normal forwards;
  top: 0;
  position: inherit;
  padding-left: 20px;
  border-left: 20px solid #395271;
  height: 0;
  text-align: start;
}
@keyframes equality {
  0% {
    height: 0;
  }
  100% {
    height: 88%;
  }
}

.progress-value-responsibility {
  animation: responsibility 7s normal forwards;
  top: 0;
  position: inherit;
  padding-left: 20px;
  border-left: 20px solid #395271;
  height: 0;
  text-align: start;
}
@keyframes responsibility {
  0% {
    height: 0;
  }
  100% {
    height: 62%;
  }
}
.strip-form {
  .form-control {
    border: 2px solid rgb(150, 150, 150);
    color: white;
    padding: 10px;
    width: 100%;
    height: 4em;
    border-radius: 8px;
  }
  .btn {
    color: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 12px 30px;
    border: 2px solid rgb(150, 150, 150);
  }
}
.input-file-field{
  padding: 2px !important;
}
.input-file-field::-webkit-file-upload-button {
  background: #181818;
  color: white;
  padding: 1.3em;
}
.input-file-field:hover::-webkit-file-upload-button {
  color: black;
}
.form-field-control{
  border: 1px solid grey !important;
}
.applyhere {
  color: #fff !important;
  &:hover {
    text-decoration: unset !important;
  }
}
.textdecoration {
  color: #fff;
  &:hover {
    text-decoration: none !important;
    color: #fff !important;
  }
}
.btn-close {
  width: 50px;
  height: 50px;
}
.menunewdark,
.menu-index {
  position: unset !important;
}

.modal-backdrop {
  z-index: -1 !important;
}
.modal {
  background-color: rgba(0, 0, 0, 0.72) !important;
}
.react-tel-input .country-list {
  text-align: left !important;
  color: #000 !important;
}
#google-btn button {
  width: 100% !important;
}
.react-tel-input .form-control {
  padding-left:50px!important;
}


.carousel-img {
  width: 100%;
  height: 530px;
  object-fit: cover;
}
.carousel-item-start,
.carousel-item-next img {
  width: 100% !important;
  height: 400px !important;
}
.auto-select-redeem .ant-select-selection--single {
  padding: 0 !important;
}
.auto-select-redeem input {
  height: 49px !important;
  margin-top: 2px !important;
}

.btn-mt-3 {
  margin-top: -75px !important;
}
.redeem-form label {
  color: rgb(244, 214, 118) !important;
  font-size: 16px;
}
.redeem-steps .ant-steps-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.redeem-steps .ant-steps-item-title::after {
  display: none !important;
}
.redeem-steps .ant-steps-item-icon {
  background-color: #3ebe3c !important;
  width: 25px;
  height: 25px;
}
.redeem-steps .ant-steps-icon {
  font-size: 12px;
  top: -5px;
}
.redeem-steps .ant-steps-item-title {
  font-size: 12px;
}

.redeem-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fdac1a !important;
  color: #fff !important;
}
.redeem-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #fff !important;
}
.redeem-steps .nav-link {
  padding: 10px 15px;
}
.redeem-steps .ant-steps-label-horizontal {
  background-color: rgb(44, 44, 44);
  padding: 10px 20px;
  display: grid;
  grid-auto-flow: column;
}
.redeem-btns .ant-btn {
  background-color: #288fd3 !important;
  border-radius: 3px !important;
}
.redeem-btns {
  border-top: 2px solid #4e4e4e;
}
.redeem-steps .ant-steps-item-icon {
  border: 2px solid rgb(217, 217, 217);
}
.redeem-steps .ant-steps-icon {
  color: rgb(217, 217, 217) !important;
  vertical-align: baseline !important;
}
.redeem-steps .ant-steps-icon svg {
  vertical-align: baseline !important;
}
// .ant-steps-item-icon::after {
//   content: '';
//   width: 100%;
//   border-bottom: 2px solid #d9d9d9;
//   display: block;
//   position: absolute;
//   margin-left: 22px;
//   top: 11px;
// }
.modal-dialog-scrollable .modal-body {
  overflow-x: hidden !important;
}
.react-datepicker__day {
  width: 5rem !important;
  font-size: 15px !important;
}

.react-datepicker__day-name {
  font-size: 15px !important;
  width: 5rem !important;
}
.react-datepicker__current-month {
  font-size: 15px !important;
}
//Card Container
// @media screen and (max-width: 768px) {
//   .card-containers{
//     width: 500px!important;
//     max-height: 500px!important;
//     img{
//       height:500px!important;
//     }
// }
// }
// @media screen and (max-width: 500px) {
//   .card-containers{
//     width: 300!important;
//     max-height: 300px!important;
//     img{
//       height:300px!important;
//     }
// }
// }
// @media screen and (max-width: 400px) {
//   .card-containers{
//     width: 300!important;
//     max-height: 200px!important;
//     img{
//       height:200px!important;
//     }
// }
// }
// @media screen and (max-width: 300px) {
//   .card-containers{
//     width: 200!important;
//     max-height: 200px!important;
//     img{
//       height:200px!important;
//     }
// }
// }
.carousel-control-next-icon,
.carousel-control-prev-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    background-color: #000;
    border-radius: 80px;
    background-size: 15px;
    -webkit-filter: invert(0);
    filter: invert(0);
}
.card-containers{

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#000!important;

  video{
    width: 100%;
    height:100%;
    object-fit: contain;

  }
  img{
    width:100%;
    height:100%;
    object-fit: contain;
 
  }

}
// .square-image-wrapper {
//   position: relative;
//   width: 100%;
//   padding-bottom: 100%; /* Sets the padding based on a 1:1 aspect ratio (square) */
// }

// .fixed-size-image {
//   position: relative;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   max-width: 800px; /* Set the maximum width */
//   max-height: 800px; /* Set the maximum height */
//   object-fit: contain; /* Adjust the object-fit value as needed */
// }
.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover{
  background-color:#403e3e!important;
}
.dynamicTextColors{
  p{
    font-size: 14px!important;
    font-weight: 400px!important;
  }
    span{
    font-size: 14px!important;
    font-weight: 400px!important;
  }
}
#dynamictext{
  p{
    font-size: 14px!important;
    font-weight: 300!important;
    font-family: Montserrat!important;
  }
    span{
    font-size: 14px!important;
    font-weight: 300!important;
    font-family: Montserrat!important;
  }
  b{
 font-size: 14px!important;
    font-weight: 300!important;
    font-family: Montserrat!important;
  }
}
.img-overflow{
  overflow: hidden;
}
.carousel-control-next{
  display: flex;
  justify-content: flex-start;
}

#rc-imageselect, .g-recaptcha {
   display: inline; //the most important
}

#rc-imageselect{
   max-width: 100%;
}

.recaptcha-layout-size>div>div{
   width: 100% !important;
   height: 78px;
   transform:scale(0.77); //the code to rescale the captcha obtained in this page
   webkit-transform:scale(0.77);
   text-align: center;
   position: relative;
}
.itemContainerDescription{
  word-break: break-all;
}
.platform-text{
  padding: 50px 100px 50px;
}
.ant-dropdown-menu-vertical{
  background-color:transparent;
}
.new-filter-design{
 
  width: 500px!important;
  max-width: 100%;
  padding: 25px!important;

}
.filter-main-row{
  display: flex;
  flex-direction: column;

}
.filter-row{
  padding: 15px 0!important;
}
.filter-column{
  border-bottom: 1px solid #D9D9D9;
}
.filter-first-row{
  display: flex;
  row-gap: 5px;
}
.ant-dropdown-menu-item:hover{
  background-color: transparent!important;
}
.filter-main-row{
  position: relative;
  // .selected-option{
  //   position:absolute;
  //   left: 30%;
    
  // }
  .remove-option{
    background-color: transparent!important;
    border:0!important;
  }
.ant-dropdown-menu-vertical[role="menu"] {
  background-color: #D9D9D9 !important;
}
}
ant-dropdown-menu-item-active:hover{
  background-color: transparent;
}
.bids-cards-desciptions{
  .price{
    margin:20px 0px!important
  }
}
.color-orange{
  color: orange !important;
}
.dollar-sign{
  color: black !important;
}
.input-padding{
  padding-left: 35px !important;
}

 .selected-option{ 
    display: flex;
    flex-direction: column;
    gap:10px;
    span{
      padding: 2px 15px;
      
    }
   
 }
 #page-numbers {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  max-width: 1200px;
  margin: 20px auto;
  flex-wrap: wrap;
  // border: 1px solid #ddd; /* Add a border to the container */
}

#page-numbers li {
  font-size: 14px;
  // border-width: 1px 1px 1px 0px;
  // border-style: solid;
  // border-color: rgba(255,255,255,0.6);
  // margin: 0 2px;
  cursor: pointer;
  padding: 2px 10px;
  background-color: transparent;
  margin: 0 4px;
  // color: #fff;
  // border-radius: 4px;
  transition: background-color 0.3s;
  border-radius: 100px;
}
// #page-numbers .pagination-back{
//   border-left: 1px solid rgba(255,255,255,0.6);
// }
#page-numbers li:hover {
  border-radius: 100px;
  background-color: white;
  color: black !important;
}
.pagination-number-active{
  border-radius: 100px;
background-color: white !important;
color: black !important;
}
.pagination-disabled{
  background-color: transparent !important;
  display: none;
}
#page-numbers .pagination-disabled:hover{
  color: white !important;
  cursor: default !important;
}
.Approved-account-status{
  color: rgb(11, 194, 11) !important;
  text-align: center;
  font-size: 20px;
}
.color-imp {
  color: #000000d9 !important;
}